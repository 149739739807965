/* Details container styling */
.details-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    margin-top:50px
  }
  
  /* Heading styling */
  .details-heading {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 50px;
    text-align: center;
  }
  
  /* Grid container for services */
  .details-grid {
    display: grid;
    grid-template-columns: repeat(2, 140px); /* Single column by default */
    gap: 20px;
    margin-bottom: 20px;
    place-item:center;
    justify-content:center;
  }
  
  @media (min-width: 768px) {
    .details-grid {
      grid-template-columns: repeat(2, 200px); /* Two columns for medium screens */
    }
  }
  
  @media (min-width: 1024px) {
    .details-grid {
      grid-template-columns: repeat(2, 300px); /* Three columns for large screens */
    }
  }
  
  /* Error message styling */
  .details-error {
    color: #e74c3c;
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Button container */
  .details-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  /* Button styling */
  .details-button {
    padding: 10px 20px;
    background-color: #ff5464;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .details-button:hover {   
    background-color: #faafb6;
    color:black;
  }
  


  /* New Form Styling */
.form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-item {
    display: flex;
    flex-direction: column;
}

.form-item label {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}

.input-field,
.textarea-field {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    font-size: 1rem;
    color: #333;
    transition: border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
}

.input-field:focus,
.textarea-field:focus {
    border-color: #faafb6;
    outline: none;
}

.textarea-field {
    height: 100px;
    resize: none;
}

/* Error feedback styling */
.error-feedback {
    font-size: 0.8rem;
    color: #e74c3c;
    margin-top: 5px;
}

/* Button container for the form */
.button-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.back-button {
    background-color: #ddd;
    color: #333;
}

.back-button:hover {
    background-color: #ccc;
}

.continue-button {
    background-color: #ff5464;
    color: #fff;
}

.continue-button:hover {
    background-color: #faafb6;
    color:black;
}



/* Review */


/* Wrapper styles */
.details__wrapper {
    border-radius: 8px;
    padding: 20px;
    margin: 20px auto;
    max-width: 800px; /* Limit width for readability */
  }
  
  /* Section titles */
  .details__wrapper h1 {
    font-size: 1.5rem;
    color: #333;
    border-bottom: 2px solid #ff5464; /* Add a blue underline */
    padding-bottom: 5px;
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  /* Text styles */
  .details__wrapper p {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin: 5px 0;
  }
  
  .details__wrapper p strong {
    color: #000; /* Strong emphasis */
  }
  
  /* Button container */
  .button__items {
    display: flex;
    justify-content: space-between;
    gap: 20px;  }
  
  /* Buttons */
  .button__items .ant-btn-default {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .button__items .ant-btn-default:hover {
    background-color: #ff5464;
    color: #fff;
    cursor:pointer;
  }
  
  .button__items .ant-btn-primary {
    background-color:#ff5464;
    padding: 10px 20px;
    color:white;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .button__items .ant-btn-primary:hover {
    background-color: #faafb6;
    color:black;
  }
  
  /* Grid styles */
  .ant-row {
    margin: 0 -8px; /* Align the grid properly */
  }
  
  .ant-col {
    padding: 8px;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .details__wrapper {
      padding: 15px;
      margin: 15px;
    }
  
    .details__wrapper h1 {
      font-size: 1.25rem;
    }
  
    .details__wrapper p {
      font-size: 0.9rem;
    }
  
    .button__items .ant-btn-default,
    .button__items .ant-btn-primary {
      padding: 6px 12px;
      font-size: 0.9rem;
    }
  }
  

  /* Confirmation */

  /* Wrapper styles */
.confirmation-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Icon styles */
  .confirmation-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #f0f4fa; /* Light background for the icon */
    margin-bottom: 20px;
  }
  
  .confirmation-icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  
  /* Title styles */
  .confirmation-title {
    font-size: 1.5rem; /* Adjust font size */
    color: #0056b3; /* Denim color */
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
    line-height: 1.2;
  }
  
  /* Subtitle styles */
  .confirmation-subtitle {
    font-size: 1rem;
    color: #7a7a7a; /* Gray color */
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  